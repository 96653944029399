<template>
  <v-app id="app">
    <transition name="slide">
      <keep-alive>
        <router-view ref="content" @fetch-post="fetchPost" @reload="reload" :topics="topics" :rawPosts="rawPosts"
          :featuredPosts="featuredPosts" :tags="tags" :loading="loading" :links="links" :weather="weather"
          :menus="menus" />
      </keep-alive>
    </transition>
    <div class="footer">
      <div class="footer-inner"><img src="@/assets/footer.svg" alt="海士町の「いま」をまとめました あまとめ 島根県隠岐郡海士町" /></div>
    </div>
  </v-app>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

if (Capacitor.isNativePlatform()) {
  window.open = async url => Browser.open({ url });
}

export default {
  data() {
    return {
      topics: [],
      rawPosts: [],
      featuredPosts: [],
      tags: [],
      links: [],
      weather: [],
      menus: [],
      loading: true,
    }
  },

  created() {
    if (this.loading) {
      this.fetchTopics()
      this.fetchPosts(false)
      this.fetchFeaturedPosts()
      this.fetchLinks()
      this.fetchWeather()
      this.fetchMenus()
      this.$gtag.pageview({
        page_path: "/",
        page_title: "トップページ"
      })
    }
  },

  /*
    mounted() {
      var positionY = sessionStorage.getItem('positionY')
      scrollTo(0, positionY);
      setTimeout(function(){
          scrollTo(0, positionY);
      }, 500);
    },
  */
  components: {
  },
  methods: {
    fetchTopics() {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'topics')
        .then((response) => {
          this.topics = response.data['topics'];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchPosts(reload) {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'articles')
        .then((response) => {
          if (reload) {
            this.rawPosts = response.data['articles'];
          } else {
            this.rawPosts = this.rawPosts.concat(response.data['articles']);
          }
          this.rawPosts = this.rawPosts.filter((element, index, self) =>
            self.findIndex(e =>
              e.guid === element.guid
            ) === index
          );
          this.loading = false;
        }
        )
        .catch((e) => {
          console.log(e);
        });
    },
    fetchPost(guid) {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'article/' + guid)
        .then((response) => {
          this.rawPosts.push(response.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchFeaturedPosts() {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'featured_posts')
        .then((response) => {
          this.featuredPosts = response.data['articles'];
          this.tags = response.data['tags'];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchLinks() {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'links')
        .then((response) => {
          this.links = response.data['links'];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchWeather() {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'weather')
        .then((response) => {
          this.weather = response.data['weather'];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchMenus() {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'menus')
        .then((response) => {
          this.menus = response.data['menus'];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    trackOutboundLink(url, title) {
      this.$gtag.event('click', {
        'event_category': 'news_external_link',
        'event_label': title,
        'value': 1
      });
    },
    reload() {
      this.fetchTopics()
      this.fetchPosts(true)
      this.fetchFeaturedPosts()
      this.fetchLinks()
      this.fetchWeather()
      this.fetchMenus()
      this.$gtag.pageview({
        page_path: "/",
        page_title: "トップページ(リロード)"
      })
    }
  },

  metaInfo: {
    title: 'あまとめ - 海士町公式まとめサイト -',
    meta: [
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge', charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, viewport-fit=cover' },
      {
        'vmid': 'description',
        'name': 'description',
        'content': '島まるごと、総まとめ。「あまとめ」は、海士町に関するあらゆる情報を集めた、海士町役場公式のまとめサイトです。'
      },
      {
        'vmid': 'og:type',
        'property': 'og:type',
        'content': 'website'
      },
      {
        'vmid': 'og:url',
        'property': 'og:url',
        'content': 'https://amatome.town.ama.shimane.jp/'
      },
      {
        'vmid': 'og:title',
        'property': 'og:title',
        'content': 'あまとめ - 海士町公式まとめサイト -'
      },
      {
        'vmid': 'og:image',
        'property': 'og:image',
        'content': 'https://amatome.town.ama.shimane.jp/amatome-ogp.jpg'
      },
      {
        'vmid': 'og:description',
        'property': 'og:description',
        'content': '島まるごと、総まとめ。「あまとめ」は、海士町に関するあらゆる情報を集めた、海士町役場公式のまとめサイトです。'
      },
      {
        'vmid': 'twitter:image:src',
        'name': 'twitter:image:src',
        'content': 'https://amatome.town.ama.shimane.jp/amatome-ogp.jpg'
      },
      {
        'vmid': 'twitter:card',
        'name': 'twitter:card',
        'content': 'summary_large_image'
      },
      {
        'vmid': 'twitter:title',
        'name': 'twitter:title',
        'content': 'あまとめ - 海士町公式まとめサイト -'
      },
      {
        'vmid': 'twitter:description',
        'name': 'twitter:description',
        'content': '島まるごと、総まとめ。「あまとめ」は、海士町に関するあらゆる情報を集めた、海士町役場公式のまとめサイトです。'
      }
    ],
    script: [
      {
        vmid: 'jsonld',
        innerHTML: JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          'url': 'https://amatome.town.ama.shimane.jp',
          'name': 'あまとめ - 海士町公式まとめサイト -',
          'publisher': {
            '@type': 'Organization',
            'name': '海士町',
            'url': 'http://www.town.ama.shimane.jp',
            'logo': {
              '@type': 'ImageObject',
              'url': 'http://www.town.ama.shimane.jp/img/logo.jpg'
            }
          }
        }),
        type: 'application/ld+json'
      }
    ],
    link: [
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: 'https://amatome.town.ama.shimane.jp/'
      }
    ],
    noscript: [
      { innerHTML: 'This website requires JavaScript.' }
    ]
  }
}
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "palt";
  color: #2c3e50;
  margin: 0 auto;
  position: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.footer {
  text-align: center;
  letter-spacing: 2px;
  padding: 10px 0;
  margin-bottom: 56px;
}

.footer img {
  max-width: 768px;
}

.footer-inner {
  display: flex;
  justify-content: center;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 100%;
}
</style>
<style>
.v-menu__content {
  left: 12px !important;
}

.v-navigation-drawer {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.header-toolbar {
  position: fixed;
  padding: 0 10px;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  box-sizing: border-box;
  background: white;
  width: 100%;
  height: calc(56px + constant(safe-area-inset-top));
  height: calc(56px + env(safe-area-inset-top));
  border-bottom: 1px solid;
  z-index: 10;
}

.header-toolbar-inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-toolbar-inner>* {
  flex: 1;
}

.header-toolbar-inner>h1 {
  flex: 2;
}

.header-toolbar-left {
  text-align: left;
}

.header-toolbar-center {
  text-align: center;
  flex: 4;
}

.header-toolbar-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.grecaptcha-badge {
  visibility: hidden;
}

form .v-messages__message {
  line-height: 14px;
}
</style>