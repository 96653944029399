var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-inner"},[_c('div',{staticClass:"links",class:_vm.active ? 'links-active' : ''},[_c('div',{staticClass:"links-row-col-1"},[_c('a',{staticClass:"link-btn-lg",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: {
              category: '海士町役場オフィシャル',
              links: _vm.links['official'],
            },
          })}}},[_c('div',{staticClass:"link-btn-lg-icon"},[_vm._v("海士")]),_vm._m(0)])]),_c('div',{staticClass:"links-row-col-3"},[_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '観光', links: _vm.links['sightseeing'] },
          })}}},[_vm._m(1)]),_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '産業', links: _vm.links['industry'] },
          })}}},[_vm._m(2)]),_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '教育', links: _vm.links['education'] },
          })}}},[_vm._m(3)])]),_c('div',{staticClass:"links-row-col-3"},[_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '福祉', links: _vm.links['welfare'] },
          })}}},[_vm._m(4)]),_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '商工', links: _vm.links['business'] },
          })}}},[_vm._m(5)]),_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '文化', links: _vm.links['culture'] },
          })}}},[_vm._m(6)])]),_c('div',{staticClass:"links-row-col-3"},[_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '外部リンク', links: _vm.links['external'] },
          })}}},[_vm._m(7)]),_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: '隠岐', links: _vm.links['oki'] },
          })}}},[_vm._m(8)]),_c('a',{staticClass:"link-btn-sm",on:{"click":function($event){return _vm.$router.push({
            name: 'link-detail',
            params: { category: 'アクセス', links: _vm.links['access'] },
          })}}},[_vm._m(9)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-lg-text"},[_vm._v(" 海士町役場"),_c('br'),_vm._v(" オフィシャルサイト一覧 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("観光")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("産業")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("教育")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("福祉")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("商工")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("文化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon2"},[_c('div',[_vm._v("外部"),_c('br'),_vm._v("リンク")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon"},[_c('div',[_vm._v("隠岐")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-btn-sm-icon2"},[_c('div',[_vm._v("アク"),_c('br'),_vm._v("セス")])])
}]

export { render, staticRenderFns }