<template>
  <div class="source">
    <span class="link" v-if="article.source_url" :href="article.source_url"
      v-on:click="openInBrowser(article.source_url, eventCategory, article.source)">
      {{ article.source }}
    </span>
    <span v-else>{{ article.source }}</span>
  </div>
</template>

<script>
export default {
  props: ['article', 'eventCategory'],
  name: 'postsListItemSource',
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.link {
  color: #1976d2;
  text-decoration: none;
}
</style>
