<template>
  <div :class="articleType">
    <div class="news-topic-thumb" v-if="article.image">
      <h3 v-if="isTopic">topic</h3>
      <span class="link" v-if="!article.content" :href="article.url"
        v-on:click="trackOutboundLink(article.url, article.title, article.guid)"><img v-lazy="article.image" /></span>
      <div v-else @click="linkToArticle(article.guid)">
        <img v-lazy="article.image" />
      </div>
    </div>
    <div class="news-topic-text">
      <PostsListItemTitle :article="article" eventCategory="news_external_link" />
      <div class="news-footer">
        <div class="news-footer-left">
          <PostsListItemSource :article="article" eventCategory="news_external_link" />
          <div v-if="article.date && !isTopic" class="news-date" :class="this.dayjs(article.date, 'YYYY/M/D') ===
              this.dayjs(Date.now(), 'YYYY/M/D')
              ? 'today-news'
              : ''
            ">
            {{ article.date | dayjs("YYYY/M/D") }}
          </div>
        </div>
        <div class="news-footer-right">
          <v-icon @click="$emit('share', article)">mdi-dots-vertical</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import PostsListItemTitle from "./PostsListItemTitle";
import PostsListItemSource from "./PostsListItemSource";

export default {
  props: ["article", "isTopic"],
  name: "newsListItem",
  components: { PostsListItemTitle, PostsListItemSource },
  data() {
    return {};
  },
  computed: {
    articleType() {
      return this.isTopic ? "news-topic-item" : "news-list-item";
    },
  },
  methods: {
    dayjs(value, format) {
      return dayjs(value).format(format);
    },
    getScrollTop() {
      return document.documentElement.scrollTop || document.body.scrollTop;
    },
    trackOutboundLink(url, title, guid) {
      var positionY = this.getScrollTop();
      sessionStorage.setItem("positionY", positionY);
      this.$gtag.event("click", {
        event_category: "news_external_link",
        event_label: title,
        value: 1,
      });
      this.axios.get(process.env.VUE_APP_API_BASE_URL + "article_view/" + guid);
      window.open(url, "_blank", "noopener,noreferrer");
    },
    linkToArticle(guid) {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + "article_view/" + guid);
      this.$router.push({ name: "article", params: { guid: guid } });
    },
  },
  filters: {
    /**
     * @param {Date} value    - Date オブジェクト
     * @param {string} format - 変換したいフォーマット
     */
    dayjs(value, format) {
      return dayjs(value).format(format);
    },
  },
};
</script>

<style scoped>
h3 {
  position: absolute;
  background: #ea554e;
  padding: 0px 4px;
  color: white;
}

.link {
  color: #1976d2;
  text-decoration: none;
}

.news-topic-item {
  width: 100%;
  display: flex;
}

.news-list-item {
  font-size: 16px;
  height: calc(100% - 20px);
  border-right: solid 1px #b5b5b6;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.news-list-item-wrapper:nth-child(3n) .news-list-item {
  padding-right: 0;
  padding-left: 10px;
  border-right: none;
}

.news-list-item-wrapper:nth-child(3n-1) .news-list-item {
  padding-left: 10px;
}

@media screen and (max-width: 480px) {
  .news-list-item-wrapper:nth-child(3n-1) .news-list-item {
    padding-left: 0;
  }

  .news-list-item-wrapper:nth-child(3n) .news-list-item {
    padding-right: 10px;
    padding-left: 0;
    border-right: solid 1px #b5b5b6;
  }

  .news-list-item-wrapper:nth-child(2n) .news-list-item {
    padding-right: 0;
    padding-left: 10px;
    border-right: none;
  }
}

.news-topic-thumb img {
  width: 100%;
  max-height: 120px;
  height: 30vw;
  object-fit: cover;
}

.news-topic-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-list-row-col-1 .news-topic-text {
  padding-left: 10px;
}

.news-list-row-col-1 .news-topic-thumb {
  flex: 1;
}

.news-list-row-col-2 .news-list-item:nth-child(2) {
  padding-right: 0;
  padding-left: 10px;
  border-right: none;
}

.news-list-row-col-2 .news-list-item {
  margin-top: 0;
  margin-bottom: 0;
  flex: 1;
}

.news-title a {
  color: #2c3e50;
  pointer-events: auto;
}

.today-news {
  color: #ea554e;
}

.news-source,
.news-date {
  font-size: 12px;
}

.news-footer {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
